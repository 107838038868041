import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProjectsPage from './pages/ProjectsPage';
import ContactPage from './pages/ContactPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "/contact",
    element: <ContactPage/>,
  },
  {
    path: "/about",
    element: <AboutPage/>,
  },
  {
    path: "/Projects",
    element: <ProjectsPage/>,
  },

]);

export default function CustomStyles() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}