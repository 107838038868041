import React from 'react';
import PageContainer from '../components/PageContainer';
import SimpleSection from '../components/SimpleSection';

import { aboutContent, joinUsContent } from '../content';
import { Box } from '@mui/material';
import { socialContent, aboutPageFeaturedIndex } from '../embeds/socialContent';
import SocialContentWrapper from '../components/SocialContentWrapper';
import IntroHeader from '../components/IntroHeader';

const title = "About Us";
const text = "We are a project-based chamber choir based in Austin, TX.  We tell stories with a choral soundtrack."

export default function AboutPage() {

    function handleJoinButtonClick(): void {
        throw new Error('Function not implemented.');
    }

    return (
        <PageContainer headerType="standard" page="About">
            <IntroHeader title={title} text={text} />
            <SimpleSection {...aboutContent} />
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                    <SimpleSection {...joinUsContent} index={1} onButtonClick={handleJoinButtonClick} />
                </Box>
                <Box sx={{ width: { xs: "100%", md: "40%" }, bgcolor: "primary.dark", display:"flex", justifyContent:"Center"  }}>
                    <SocialContentWrapper content={socialContent[aboutPageFeaturedIndex]} />
                </Box>
            </Box>
        </PageContainer>
    );
}
