import { Box, Stack, Typography } from "@mui/material";
import { SectionParagraph } from "../types";
import SectionParagraphList from "./SectionParagraphList";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

type Props = {
    index: number,
    title: string,
    subtitle?: string,
    about: SectionParagraph[],
    songs: SectionParagraph[],
    abstract: SectionParagraph[],
    videoId?: string,
    playlistId?: string,
    playlistCoverId?:string,
}

export default function ProjectSection({ index, title, subtitle, about, abstract, songs, videoId, playlistId, playlistCoverId }: Props) {

    const odd = index % 2;
    const bgColor = odd ? "secondary.main" : "primary.light";
    const fontColor = odd ? "primary.light" : "secondary.dark";


    const renderAbstract = () => {
        return (
            <Box mt={2} mr={2}>
                <Typography variant="h6" color={fontColor} sx={{ mb: 2, fontWeight: 800 }}>Abstract</Typography>
                {abstract && <SectionParagraphList id={`cs-${index}-abs`} paragraphs={abstract} color={odd ? "primary" : "secondary"} />}
            </Box>);
    }

    const renderAbout = () => {
        return (
            <Box mt={2} mr={2}>
                <Typography variant="h5" color={fontColor} sx={{ mb: 2, fontWeight: 800 }}>About this Production</Typography>
                {about && <SectionParagraphList id={`cs-${index}-about`} paragraphs={about} color={odd ? "primary" : "secondary"} />}
            </Box>);
    }
    const renderVideo = () => {
        if (!videoId && !playlistId) return <></>;
        return (
            <Box sx={{ mt: 3, mx: "auto", width: "75%", border: 1 }}>
                
                {videoId && <LiteYouTubeEmbed id={videoId} title={title} />}
                {playlistId && playlistCoverId && <LiteYouTubeEmbed id={playlistId} playlist = {true} title={title} playlistCoverId = {playlistCoverId}/>}
            </Box>
        )
    };
    const renderSongs = () => {
        return (
            <Box mt={2} mr={2}>
                <Typography variant="h5" color={fontColor} sx={{ mb: 2, fontWeight: 800 }}>Songs</Typography>
                {songs && <SectionParagraphList id={`cs-${index}-songs`} paragraphs={songs} color={odd ? "primary" : "secondary"} />}
            </Box>);
    }

    return (
        <Box bgcolor={bgColor} width="100%">
            <Box p={2}>

                {title && <Typography sx={{ mx: 3, mb: 2, mt: 2 }} variant="h2" color={fontColor} >{title}</Typography>}
                {subtitle && <Typography sx={{ mx: 3, mb: 2 }} variant="body1" color={fontColor} >{subtitle}</Typography>}
                <Box sx={{ display: "flex", flexWrap: 'wrap', width: "100%", mb: 4 }}>
                    <Stack sx={{ width: { xs: "100%", sm: "60%" } }}>
                        <Box sx={{ px: 3 }}>
                            {renderAbstract()}
                            {renderVideo()}
                        </Box>
                    </Stack>
                    <Stack sx={{ width: { xs: "100%", sm: "40%" } }}>
                        <Box sx={{ px: 3 }}>
                            {renderSongs()}
                            {renderAbout()}
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
}
