import React from 'react';
import PageContainer from '../components/PageContainer';
import ProjectSection from '../components/ProjectSection';
import {ProjectsContent as Projects} from '../content';
import IntroHeader from '../components/IntroHeader';

const title = "Projects";
const text = `Here are some of the projects we have worked on.`;

export default function ProjectsPage() {

    return (
        <PageContainer headerType="standard" page = "Projects">
            <IntroHeader title = {title} text = {text}/>
            {Projects.map((c,ix) => (
                <ProjectSection 
                    key ={`cs-${ix}`}
                    index = {ix}
                    {...c}/>
            ))}
        </PageContainer>
    )
}
